import { EntityConfig, getPermissionGroup, getUserFriendlyName } from '@core/models/entity-config.model';
import { Component, HostListener, inject, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppRoutes } from '@core/enums/routes.enum';
import { saveAs } from 'file-saver';
import { QrCodeService } from '@core/api/qr-code.service';
import { QrCode } from '@core/models/qr-code.model';
import { LayoutUtilsService } from '@shared/services/layout-utils.service';
import { NotificationService } from '@shared/modules/notification/notification.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { NgxPermissionsService } from 'ngx-permissions';
import { TableListAction } from '@core/enums/table-list-action.enum';
import { BaseListComponent } from '@capturum/shared';
import { onEnterKeyDown } from '@core/decorators/on-enter-key-down.decorator';
import { DateHelper } from '@core/utils/date.helper';
import { TableFilter } from '@core/models/table-filter.model';
import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';
import { DefaultSort } from '@core/models/defaultSort.model';
import { Entity } from '@core/enums/entity.enum';
import { LayoutConfig, LayoutConfigService } from '@shared/services/layout-config.service';
import { LazyLoadEventUtils } from '@core/utils/lazy-load-event-utils';
import { OverviewConfigService } from '@shared/services/overview-config.service';
import { FilterMetadata } from 'primeng/api';
import { debounceTime, distinctUntilChanged, skip, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { GeneralSelectors } from '@store/admin/general/general.selectors';
import { Farm } from '@core/models/farm.model';
import { filterAsync } from '@core/utils/functions/async-filter.util';
import { SortDirection } from '@core/enums/ui-general.enum';
import { FilterMatchMode, TableAction, TableActionEvent, TableColumn, TableColumnTypes } from '@capturum/ui/api';
import { TableComponent } from '@capturum/ui/table';
import { CalendarConfig, CapturumCalendarService } from '@capturum/ui/calendar';
import { FormAction } from '@core/enums/form-action.enum';
import { CrudAction } from '@core/enums/crud-action.enum';
import { BaseConfirmService } from '@core/services/base-confirm.service';
import { responseData } from '@capturum/builders/core';

interface Filters {
  [s: string]: FilterMetadata;
}

@Component({
  template: '',
})
export class DxpBaseListComponent<Model extends { id: string; qrCode?: QrCode }>
  extends BaseListComponent<Model>
  implements OnInit, OnDestroy
{
  public routes: typeof AppRoutes = AppRoutes;
  public TableListAction: typeof TableListAction = TableListAction;
  public Entity: typeof Entity = Entity;
  public FormAction: typeof FormAction = FormAction;
  public CrudAction: typeof CrudAction = CrudAction;
  public tableActions: TableAction[] = [];
  public clickable: boolean;
  public filtersStyle = {
    filterWrapperStyleClass: '',
    filterStyleClass: 'px-3 cap-rounded',
    tableFiltersStyleClass: 'table-filters-row-wrap',
    wrapperFiltersStyleClass: 'd-md-flex justify-content-between',
  };

  public activeFarm$: Observable<Farm>;

  @ViewChild('dataTable') public dataTable: TableComponent;

  public calendarLocale: Observable<CalendarConfig>; // @TODO: Fix in Emendis/UI and remove this line

  protected entityConfig: EntityConfig;
  protected automaticallyApplyFilters = true;
  protected defaultSort: DefaultSort[] = [];
  protected filters$: BehaviorSubject<Filters> = new BehaviorSubject<Filters>({});
  protected router: Router;
  protected ngxPermissionService: NgxPermissionsService;
  protected qrCodeService: QrCodeService;
  protected baseConfirmService: BaseConfirmService;
  protected layoutConfigService: LayoutConfigService;
  protected overviewConfigService: OverviewConfigService<Model>;
  protected capCalendarService: CapturumCalendarService;
  protected destroy$: Subject<boolean> = new Subject();
  protected http: HttpClient;
  protected readonly subscriptions: Subscription = new Subscription();
  private store = inject(Store);

  constructor(
    public injector: Injector,
    public translateService: TranslateService,
    public notificationService: NotificationService,
    public layoutUtilsService: LayoutUtilsService,
  ) {
    super(injector, translateService);

    this.baseConfirmService = injector.get(BaseConfirmService);
    this.qrCodeService = injector.get(QrCodeService);
    this.ngxPermissionService = injector.get(NgxPermissionsService);
    this.layoutConfigService = injector.get(LayoutConfigService);
    this.overviewConfigService = injector.get(OverviewConfigService);
    this.capCalendarService = injector.get(CapturumCalendarService);
    this.router = injector.get(Router);
    this.http = injector.get(HttpClient);

    this.activeFarm$ = this.store.select(GeneralSelectors.getActiveFarm);
    this.loading = true;
    this.filters = {};
    this.columns = [];
    this.calendarLocale = this.capCalendarService.getConfig(); // @TODO: Fix in Emendis/UI and remove this line

    this.loadTableDataCallback = (items) => {
      this.defaultLoadTableCallBackLogic(items);
    };
  }

  /***
   * Default actions to display on list.
   * Use a getter so translations will work when refreshing the page!
   */
  protected get defaultActions(): TableAction[] {
    return [this.generateDeleteAction, this.generateQrAction];
  }

  protected get generateHistoryAction(): TableAction {
    return {
      label: this.translateService.stream('dxp.general.actions.history'),
      value: TableListAction.showHistory,
      key: TableListAction.showHistory,
      icon: 'fas fa-history',
      callback: () => {},
    };
  }

  protected get generateDeleteAction(): TableAction {
    return {
      label: this.translateService.stream('button.delete'),
      value: TableListAction.delete,
      key: TableListAction.delete,
      icon: 'far fa-trash-alt',
      permissions: [
        `dxp.${getPermissionGroup(this.entityConfig)}.manage`,
        `dxp.${getPermissionGroup(this.entityConfig)}.delete`,
      ],
      callback: () => {},
    };
  }

  protected get generateQrAction(): TableAction {
    return {
      label: this.translateService.stream('dxp.button.download-qr-code'),
      value: TableListAction.qrGenerate,
      key: TableListAction.qrGenerate,
      icon: 'fas fa-qrcode',
      permissions: [`dxp.qr-code.show`],
      callback: () => {},
    };
  }

  protected get generateQrSignAction(): TableAction {
    return {
      ...this.generateQrAction,
      value: TableListAction.qrGenerateSign,
      key: TableListAction.qrGenerateSign,
    };
  }

  protected get generateQrStickerAction(): TableAction {
    return {
      ...this.generateQrAction,
      value: TableListAction.qrGenerateSticker,
      key: TableListAction.qrGenerateSticker,
    };
  }

  protected get clickablePermissions(): string[] {
    return [
      `dxp.${getPermissionGroup(this.entityConfig)}.admin`,
      `dxp.${getPermissionGroup(this.entityConfig)}.manage`,
      `dxp.${getPermissionGroup(this.entityConfig)}.show`,
    ];
  }

  public ngOnInit(): void {
    if (this.automaticallyApplyFilters) {
      this.automaticallyApplyFiltersListener();
    }

    this.listenForFarmChange();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.subscriptions.unsubscribe();
  }

  public getDefaultColumnsList(): TableColumn[] {
    return [
      {
        field: 'createdByUser.name',
        type: TableColumnTypes.STRING,
        header: this.translateService.stream('dxp.general.created_by_user_id'),
        filterable: false,
      },
      {
        field: 'created_at',
        type: TableColumnTypes.DATE,
        header: this.translateService.stream('dxp.general.created_at'),
        filterable: false,
      },
    ];
  }

  public onFilter(event: TableFilter): void {
    const newFilters = LazyLoadEventUtils.parseDateFilters(event, this);

    this.callAutomaticallyApplyFilters(newFilters);
    this.filters = newFilters;
  }

  public callAutomaticallyApplyFilters(filters: Filters): void {
    if (this.automaticallyApplyFilters) {
      const applyFilters = JSON.stringify(filters) !== JSON.stringify(this.lazyLoadEvent?.filters);

      if (applyFilters) {
        this.filters$.next(filters);
      }
    }
  }

  public onTableSort(event: any): void {
    this.sort = [
      {
        field: event.field,
        direction: event.order === -1 ? SortDirection.desc : SortDirection.asc,
      },
    ];
  }

  public setFilter(value: any, field: string, matchMode: string, remove?: boolean): void {
    // Reset filter
    delete this.filters[field];

    // Just remove filter without some changes
    if (remove) {
      return;
    }

    // Do not apply new filter when no options are selected
    if (matchMode === FilterMatchMode.IN && Array.isArray(value) && value.length === 0) {
      return;
    }

    // Set new filter
    this.filters[field] = { value, matchMode };
  }

  /**
   * Overwrite this method from the BaseList class to always use onRowClick(). This is just in case some @Complete
   * page still relies on being able to call 'editItem()'.
   *
   * @param id
   * @param routePath
   */
  public editItem(id: number | string, routePath: string = this.routePath): Promise<boolean> {
    return this.onRowClick(id.toString());
  }

  public onRowClick(id: string): Promise<boolean> {
    return this.baseRouter.navigate(['/', AppRoutes.admin, getUserFriendlyName(this.entityConfig), id]);
  }

  public downloadQrCode(item: Model): void {
    if (item && item.qrCode) {
      this.notificationService.info(
        this.translateService.instant('dxp.toast.info.title'),
        this.translateService.instant('dxp.button.download-qr-started'),
      );

      this.subscriptions.add(
        this.qrCodeService
          .download(item.id)
          .pipe(
            responseData,
            switchMap((response) => {
              if (response?.url) {
                return this.http.get(response.url, { responseType: 'blob' });
              }

              return of(null);
            }),
          )
          .subscribe(
            (updatedResponse) => {
              saveAs(updatedResponse, `qr-code-${item.id}.pdf`);

              this.notificationService.success(
                this.translateService.instant('toast.success.title'),
                this.translateService.instant('dxp.qr-code.download.success'),
              );
            },
            () => {
              this.notificationService.error(
                this.translateService.instant('toast.error.title'),
                this.translateService.instant('toast.error.message'),
              );
            },
          ),
      );
    } else {
      this.notificationService.error(
        this.translateService.instant('toast.error.title'),
        this.translateService.instant('toast.error.message'),
      );
    }
  }

  public deleteItem(id: number | string, item: string = this.itemNames.singular): void {
    this.subscriptions.add(
      this.apiService.delete(id).subscribe(
        () => {
          this.tableVisible = false;
          this.loading = true;

          this.loadTableDataFromCurrentLazyLoadEvent();

          this.notificationService.success(`${item}`, this.translateService.instant('list.item_deleted'));
        },
        (e: HttpErrorResponse) => {
          // Notification will be shown from interceptor
          this.loading = false;
        },
      ),
    );
  }

  /**
   * Executed for tables that have [searchable]=true property.
   */
  public onSearchEvent(searchStr: string, reloadFlag: boolean): void {
    this.search = [searchStr];

    if (reloadFlag) {
      // TODO: check which is the reason for this if, we don't have the reloadFlag = true
      this.loadTableData({ globalFilter: searchStr });
    }
  }

  public parseDateFilter(filterItem: TableFilter): TableFilter {
    // Transform dates for date filter
    if (Array.isArray(filterItem?.value)) {
      filterItem.value = filterItem.value.map((date) => {
        let newDate = date;

        if (newDate) {
          newDate = DateHelper.toGeneralFormat(newDate);
        }

        return newDate;
      });
    }

    return filterItem;
  }

  public executeActions(event: TableActionEvent): void {
    switch (event.action.key) {
      case TableListAction.delete:
        this.deleteItemWithConfirm(event.item.id, this.translateService.instant('confirm.title'));
        break;
      case TableListAction.qrGenerate:
        this.downloadQrCode(event.item);
        break;
      default:
        break;
    }
  }

  public deleteItemWithConfirm(id: number | string, item: string = this.itemNames.singular, message?: string): void {
    this.loading = true;
    let confirmMessage = message;

    if (confirmMessage === undefined) {
      confirmMessage = this.translateService.instant('list.delete_confirmation');
    }

    this.baseConfirmService.confirmationService.confirm({
      header: item,
      acceptLabel: 'dxp.general.button.delete',
      message: confirmMessage,
      accept: () => {
        this.deleteItem(id, item);
      },
      reject: () => {
        this.loading = false;
      },
    });
  }

  public setDefaultSort(sort: DefaultSort[]): void {
    this.sort = [...sort];
    this.defaultSort = [...sort];
  }

  public resetFilter(): void {
    this.filters = {};

    if (this.dataTable && this.dataTable.primeTableRef) {
      if (this.dataTable.primeTableRef.filters?.global) {
        this.dataTable.primeTableRef.filters.global = null;
      }

      this.sort = this.defaultSort;
      this.dataTable.resetFilters();
    }
  }

  @HostListener('document:keydown', ['$event.code'])
  @onEnterKeyDown()
  public applyFilter(): void {
    if (this.dataTable) {
      // This setTimeout is here because we have timing issues with the calendar filters
      setTimeout(() => {
        this.dataTable.resetPagination();
        this.dataTable.checkTableState();
      }, 200); // TODO: Apparently a 100ms wasn't enough. Time to update emendis/ui to broadcast/emit an event
    }
  }

  @HostListener('document:keydown', ['$event'])
  public onF5Pressed(event: any): void {
    if (event.code === 'F5') {
      event.preventDefault();
      this.applyFilter();
    }
  }

  protected createConfig(entity?: Model): LayoutConfig {
    const name = getUserFriendlyName(this.entityConfig);
    const config = {
      name,
      entity: name,
      url: this.baseRouter.url,
      ...this.entityConfig,
    };

    const defaultConfig = this.overviewConfigService.generateDefaultConfig(entity, config);
    const updatedConfig = this.overwriteDefaultConfig(entity, defaultConfig);

    return updatedConfig;
  }

  protected defaultLoadTableCallBackLogic(items: Model[], setConfig = true): void {
    this.allowedActions().then((allowedActions) => {
      this.tableData = items;
      this.tableActions = allowedActions;
      this.loading = false;
    });

    this.checkClickPermissions();

    if (setConfig) {
      this.layoutConfigService.addConfig(this.createConfig());
    }
  }

  protected allowedActions(): Promise<TableAction[]> {
    // Filter defaultActions that the user does not have permission to see/use
    return filterAsync<TableAction>(this.defaultActions, (action) => {
      return this.ngxPermissionService.hasPermission(action.permissions);
    });
  }

  protected checkClickPermissions(): void {
    this.ngxPermissionService.hasPermission(this.clickablePermissions).then((isClickAllowed) => {
      this.clickable = isClickAllowed;
    });
  }

  protected handleError(response: HttpErrorResponse): void {
    switch (response.status) {
      case 413:
      case 414:
        this.toastService.error(
          this.translateService.instant('toast.error.title'),
          this.translateService.instant('dxp.toast.error.too-many-filters'),
        );
        break;
      default:
        this.toastService.error(
          this.translateService.instant('toast.error.title'),
          this.translateService.instant('toast.error.message'),
        );
    }

    this.loading = false;
    this.tableData = [];

    this.layoutConfigService.addConfig(this.createConfig());
  }

  protected parseFilter(field: string, filterItem: { value: any; matchMode?: string }): any {
    let value = filterItem.value;
    const operator = filterItem.matchMode;

    // Add percent signs to value for like operators
    if (['like', 'notlike'].indexOf(operator) !== -1) {
      value = `%${value}%`;
    }

    // Format date values
    if (Array.isArray(value) && value.length >= 1) {
      const isDateRange = value.every((val) => {
        return !!new Date(val).getTime();
      });

      if (isDateRange) {
        value = this.parseFilterDateFiled(value);
      }
    }

    return {
      field,
      value,
      operator,
    };
  }

  protected parseFilterDateFiled(dates: Date[]): string[] {
    return DateHelper.toFilterRangeFormat(dates);
  }

  /***
   * This method functions as a hook to overwrite the default generated config.
   *
   * @param entity The request data of the entity we requested
   * @param defaultConfig The returned default config of generateDefaultConfig()
   *
   * @returns void | Observable<any>
   */
  protected overwriteDefaultConfig(entity: Model, defaultConfig: LayoutConfig): LayoutConfig {
    return defaultConfig;
  }

  protected automaticallyApplyFiltersListener(): void {
    this.subscriptions.add(
      this.filters$.pipe(distinctUntilChanged(), debounceTime(1100), skip(1)).subscribe(() => {
        return this.applyFilter();
      }),
    );
  }

  protected handleFarmUpdate(farm?: Farm): void {
    this.applyFilter();
  }

  private listenForFarmChange(): void {
    this.subscriptions.add(
      this.activeFarm$.pipe(skip(1), distinctUntilChanged()).subscribe((farm) => {
        return this.handleFarmUpdate(farm);
      }),
    );
  }
}
